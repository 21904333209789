import api from '../../../utils/Api';

export const GET_ALL_PENDING_INVITATIONS = 'invitations/GET_ALL_PENDING';
export const POST_INVITATION = 'invitations/POST_INVITATION';
export const SELECTED_INVITATIONS = 'invitations/SELECTED_INVITATIONS';

export const getAllPendingInvitationsAction = pendingInvitations => ({
  type: GET_ALL_PENDING_INVITATIONS,
  pendingInvitations,
});

export const postInvitationAction = invitationList => ({
  type: POST_INVITATION,
  invitationList,
});

export const selectedInvitationAction = invitationList => ({
  type: SELECTED_INVITATIONS,
  invitationList,
});

export const getAllPendingInvitationsThunk = params => async dispatch => {
  try {
    const response = await api.get('/member/pending-volunteers/', params);
    if (response && response.ok) {
      dispatch(getAllPendingInvitationsAction(response.data));
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendInvitationThunk = invitationData => async () => {
  try {
    const response = await api.post('/organization/admin-invite/', invitationData);
    if (response.ok) {
      return response;
    } else {
      throw new Error('Unexpected server error. Please try again.');
    }
  } catch (error) {
    throw error;
  }
};
