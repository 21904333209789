// ** Initial State
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  memberList: [],
  params: {},
  selectedUser: null,
};

const importMemberForms = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FORM':
      let data = action.data;

      return {
        ...state,
        data,
      };
    case 'GET_MEMBER_LIST':
      let memberList = [];
      action.data.forEach(entry => {
        memberList.push({ ...entry, value: entry.pk, label: entry.name });
      });
      return {
        ...state,
        memberList,
      };
    case 'ADD_FORM':
      return { ...state };
    default:
      return { ...state };
  }
};
export default importMemberForms;
