import api from '../../../utils/Api';

export const GET_CHECKLISTS = 'checklists/GET';
export const CREATE_CHECKLIST = 'checklist/POST';
export const EDIT_CHECKLIST = 'checklist/PUT';
export const DELETE_CHECKLIST = 'checklist/DELETE';
export const CLEAR_CHECKLISTS = 'checklists/CLEAR';
export const GET_TASKS = 'tasks/GET';
export const CREATE_TASK = 'task/POST';
export const EDIT_TASK = 'task/PUT';
export const DELETE_TASK = 'task/DELETE';
export const CLEAR_TASKS = 'tasks/CLEAR';
export const GET_TEAM_MEMBERS = 'members/GET';
export const CLEAR_TEAM_MEMBERS = 'members/CLEAR';

const getChecklistsAction = checklists => {
  return {
    type: GET_CHECKLISTS,
    checklists,
  };
};

const createChecklistAction = checklist => {
  return {
    type: CREATE_CHECKLIST,
    checklist,
  };
};

const editChecklistAction = checklist => {
  return {
    type: EDIT_CHECKLIST,
    checklist,
  };
};

const deleteChecklistAction = checklistPk => {
  return {
    type: DELETE_CHECKLIST,
    checklistPk,
  };
};

export const clearChecklistsAction = () => {
  return {
    type: CLEAR_CHECKLISTS,
  };
};

export const getTasksAction = tasks => {
  return {
    type: GET_TASKS,
    tasks,
  };
};

export const createTaskAction = task => {
  return {
    type: CREATE_TASK,
    task,
  };
};

export const editTaskAction = task => {
  return {
    type: EDIT_TASK,
    task,
  };
};

export const deleteTaskAction = taskId => {
  return {
    type: DELETE_TASK,
    taskId,
  };
};

export const clearTasksAction = () => {
  return {
    type: CLEAR_TASKS,
  };
};

export const getTeamMembersAction = members => {
  return {
    type: GET_TEAM_MEMBERS,
    members,
  };
};

export const clearTeamMembersAction = () => {
  return {
    type: CLEAR_TEAM_MEMBERS,
  };
};

export const getChecklistsThunk = () => async dispatch => {
  const response = await api.get('planning/checklist/');
  dispatch(getChecklistsAction(response.data));
};

export const createChecklistThunk = payload => async dispatch => {
  try {
    const response = await api.post('planning/checklist/', payload);
    dispatch(createChecklistAction(response.data));
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editChecklistThunk = payload => async dispatch => {
  try {
    const response = await api.put('planning/checklist/', payload);
    dispatch(editChecklistAction(response.data));
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteChecklistThunk = checklistPk => async dispatch => {
  try {
    const response = await api.delete(`planning/checklist/${checklistPk}/`);
    dispatch(deleteChecklistAction(checklistPk));
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTasksThunk = checklistPk => async dispatch => {
  const response = await api.get(`planning/checklist/${checklistPk}/task/`);
  dispatch(getTasksAction(response.data));
};

export const createTaskThunk = payload => async dispatch => {
  try {
    const response = await api.post('planning/task/', payload);
    dispatch(createTaskAction(response.data));
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editTaskThunk = payload => async dispatch => {
  try {
    const response = await api.put('planning/task/', payload);
    dispatch(editTaskAction(response.data));
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTaskThunk = taskId => async dispatch => {
  try {
    await api.delete(`planning/task/${taskId}/`);
    dispatch(deleteTaskAction(taskId));
  } catch (error) {
    throw error;
  }
};

export const getTeamMembersThunk = teamPk => async dispatch => {
  const response = await api.get(`member/team/${teamPk}`);
  dispatch(getTeamMembersAction(response.data.members));
};

export const getMyTasksThunk = () => async dispatch => {
  try {
    const response = await api.get('planning/my-tasks/');
    dispatch(getTasksAction(response.data));
  } catch (error) {
    throw error;
  }
};
